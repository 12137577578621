<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header2.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
              row:{}
            }
        },
        mounted() {
            this.$set(this.$root, 'page', this);
            Vue.component("Header",()=>import("@frontend/part/Header2.vue"))
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            this.apiGetStaticContent()
        },
        methods: {
        	apiGetStaticContent(){
            Gen.apirest('/static-toc', {}, (err, resp)=>{
                if(err) console.log(err)
                this.row=resp.data
                this.$root.topProgress.done()
            })
          },
        }
    };
</script>
<template>
    <div Content>
      	<Header></Header>
        <section id="page-title" class="page-title-mini">
            <div class="container clearfix">
                <h1 class="d-none d-md-block">&nbsp;</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ web.mn_syarat_ketentuan }}</li>
                </ol>
            </div>
        </section>
        <section id="content" class="overflow-none">
            <div class="content-wrap">
                <section class="section notopmargin nobottommargin">
                    <div class="container clearfix">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="highlight_txt text-center">
                                    <h2>{{row.msc_title}}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="privacy_policy_wrap" v-html="row.msc_desc">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>